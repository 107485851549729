import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

import ManufacturerInfo from './erp/ManufacturerInfo';
import CommodityManage from './erp/CommodityManage';
import OrdersManage from './erp/OrdersManage';
import SalesAmountManage from './erp/SalesAmountManage';
import ReclaimLogsManage from './erp/ReclaimLogsManage';
import TicketsManage from './erp/TicketsManage';
import DashboardAppPage from './pages/DashboardAppPage';
import FreeShippingManage from './erp/FreeShippingManage';
import BroadcastMessageForm from './erp/BroadcastMessageForm';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/login" replace />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/manufacturer',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/info" />, index: true },
        { path: 'info', element: <ManufacturerInfo /> },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'broadcast', element: <BroadcastMessageForm /> },
        // { path: 'broadcast', element: <LineApp /> },
        { path: 'commodity', element: <CommodityManage /> },
        { path: 'orders', element: <OrdersManage /> },
        { path: 'salesamount', element: <SalesAmountManage /> },
        { path: 'reclaim', element: <ReclaimLogsManage /> },
        { path: 'tickets', element: <TicketsManage /> },
        { path: 'freeshipping', element: <FreeShippingManage /> },
        // { path: 'production', element: <ProductsPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
