// src/pages/LoginPage.js

import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
import ShootingStars from '../sections/ShootingStars'; // Import the ShootingStars component
// sections
import LoginForm from '../sections/auth/login/LoginForm';
// Vanta.js
import * as THREE from 'three';
import GLOBE from 'vanta/dist/vanta.globe.min';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'relative',
  overflow: 'hidden',
}));

const StyledContent = styled('div')(({ theme }) => ({
  zIndex: 2,
  position: 'relative',
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const FooterInfo = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 20,
  right: 20,
  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  boxShadow: theme.shadows[4],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  borderRadius: theme.shape.borderRadius,
  color: '#fff',
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const vantaRef = useRef(null);
  const [vantaEffect, setVantaEffect] = useState(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        GLOBE({
          el: vantaRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0x00ffff,
          backgroundColor: 0x000000,
          points: 12.0,
          maxDistance: 20.0,
          spacing: 15.0,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <>
      <Helmet>
        <title>剩蛋快樂-廠商後台系統</title>
      </Helmet>

      <StyledRoot ref={vantaRef}>
        {/* Overlay the ShootingStars component */}
        <ShootingStars />

        <StyledContent>
          <Typography variant="h4" align="center" gutterBottom color="#fff">
            剩蛋快樂廠商後台系統
          </Typography>

          <LoginForm />

          <FooterInfo>
            <Typography variant="caption" color="inherit">
              Version 24.0920
            </Typography>
            <Typography variant="caption" color="inherit">
              尊嘟假嘟工作室 設計 
            </Typography>
            <Typography variant="caption" color="inherit">
              聯絡方式: onlyeggisreal@gmail.com
            </Typography>
          </FooterInfo>
        </StyledContent>
      </StyledRoot>
    </>
  );
}
