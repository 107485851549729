// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const AdminConfig = [
  {
    title: '廠商資訊',
    path: '/manufacturer/info',
    icon: icon('manufacturer')
  },
  {
    title: '銷售統計',
    path: '/manufacturer/dashboard',
    icon: icon('dashboard')
  },
  {
    title: '推播管理',
    path: '/manufacturer/broadcast',
    icon: icon('broadcast')
  },
  {
    title: '賞品管理',
    path: '/manufacturer/commodity',
    icon: icon('commodity')
  },
  {
    title: '訂單管理',
    path: '/manufacturer/orders',
    icon: icon('orders')
  },
  {
    title: '銷售管理',
    path: '/manufacturer/salesamount',
    icon: icon('salesamount')
  },
  {
    title: '回收列表',
    path: '/manufacturer/reclaim',
    icon: icon('reclaim')
  },
  {
    title: '抽獎票券',
    path: '/manufacturer/tickets',
    icon: icon('tickets')
  },
  {
    title: '免運票券',
    path: '/manufacturer/freeshipping',
    icon: icon('free')
  },
];

export default AdminConfig;
